.micro-trainings {

    .micro-trainings-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .align-center {
        display: flex; 
        justify-content: center;
    }

    .vertical-center {
        display: flex; 
        align-items: center;

        @media (max-width: 920px) {
            flex-direction: column;
        }
    }

    .response-types {
        display: flex;
        justify-content: center;
        align-items: center;
    }

}

.response-field-wrapper {
    display: flex;
    position: relative;
}

.autocomplete-full-width {
    flex-grow: 1;
}

.response-field-wrapper.spacing {
    padding-right: 40px;
}