.files-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    align-items: center;
    height: 100%;

    img {
        max-width: 70vw;
        object-fit: cover;
        max-height: 500px;
        width: 100%;

        @media (max-width: 800px) {
            max-width: 90vw;
        }
    }

    object{
        height: 70vh;
        width: 80vw;

        @media (min-width: 800px) {
            width: 70vw;
        }
    }

    #video-download {
        max-width: 60vw;

        @media (max-width: 800px) {
            max-width: 80vw;
        }
    }
}
  