.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 10px 30px 10px;
  background-color: var(--bg-main);
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
  position: fixed; /* Add this */
  top: 0; /* Add this */
  left: 0;
  width: 100%; /* Add this */
  height: 72px;
  z-index: 1000; /* Add this to ensure header is on top of other content */

  @media (min-width: 600px) {
    padding: 30px 20px 30px 20px;
    gap: 20px;
  }
}

.logo {
  height: 40px !important;
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  width: 100vw;
}

.anemon-logo {
  width: auto;
  display: flex;
  display: -webkit-flex;
  height: 40px !important;
}

.profile :hover {
  cursor: pointer;
}

.back-button {
  color: rgb(51, 51, 204);
  cursor: pointer;
}

.logo .organization-avatar {
  width: auto;
  min-width: 40px;
  height: 40px !important;
}

.header-logos-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  @media (min-width: 600px) {
    gap: 20px;
  }
}