div.MuiTableContainer-root {
    background-color: inherit;
    box-shadow: none;
}

form input[type="text"].MuiInputBase-input {
    border-bottom: 0;
    margin: 0;
    padding: 0 16px 0 16px;
    height: 55px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
}

form textarea.MuiInputBase-input {
    border-bottom: 0;
}

form input[type="text"].MuiInputBase-input:focus,
form textarea.MuiInputBase-input:focus {
    border-bottom: 0;
}

input#password,
input#retryPassword {
    height: 48px;
    box-sizing: border-box;
}

div .centered-label {
    margin: 0 auto;
}

button.MuiButtonBase-root.MuiButton-root:not([disabled]) {
    i {
        color: #4546CD;
    }
}

th.MuiTableCell-root {
    border-bottom: 1px solid #c2e3d1;
}

td.MuiTableCell-root {
    border-bottom: 0;
}

button.MuiButton-containedPrimary {
    background-color: #66DEAE;
    box-shadow: none;
    color: white;
    white-space: nowrap;

    &:hover {
        background-color: rgb(71, 155, 121);
        box-shadow: none;
    }

    &:active {
        box-shadow: none;
    }

    span.MuiTouchRipple-root {
        display: none;
    }
}

button.MuiToggleButton-standard.Mui-selected[value='check'] {
    background-color: #66DEAE;

    svg {
        color: white;
    }
}

.text-align-left {
    .MuiSelect-select {
        text-align: left;
    }
}

.MuiPickersPopper-root {

    div.MuiDateCalendar-root,
    div.MuiMultiSectionDigitalClock-root {
        max-height: 300px;
    }

    div.MuiMultiSectionDigitalClock-root ul {
        &::after {
            display: none;
        }
    }

    div.MuiDialogActions-root {
        display: none;
    }
}

.question-inputs {
    .fix-label label {
        top: 8px;
    }
}

div.autocomplete-reest {
    div.MuiInputBase-root {

        input.MuiInputBase-input {
            padding: 0 15px;
        }
    }

    input {
        height: 40px;
    }

    label+div.MuiInputBase-root {
        min-height: 111px;
        align-items: flex-start;
    }
}

div.MuiInputBase-root {
    input.MuiInputBase-input {
        min-width: 130px;
    }
}

.switch-orange {
    .MuiSwitch-track {
        background-color: var(--c-orange);
    }
}

.mobile-clickable {

    label {
        pointer-events: none;
    }

    label+div {
        pointer-events: none;
        position: relative;
        padding-right: 0;
    }

    button.MuiButtonBase-root {
        pointer-events: all;
        position: absolute;
        inset: 0;
        display: flex;
        justify-content: flex-end;
        border-radius: 0;
        padding-right: 30px;

        &:hover {
            background-color: initial;
        }
    }
}