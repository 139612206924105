.footer {
  background-color: var(--bg-main);
  color: white;
  padding: 20px 20px 30px 20px;
  text-align: center;
  width: 100%;
}

.content-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;

  margin-top: 60px;
  font-size: 13px;
}

.content-footer p,
.content-footer a {
  color: #9CA3AF;
  margin-bottom: 0;
}
