div .space-bottom {
    margin-bottom: 16px;
}

table .green-border {
    border: 1px solid #c2e3d1;
}

.text-center {
    text-align: center;
}

svg.icon-success {
    font-size: 10em;
}

.centered {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.align-cell-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.shrink-text-by-row {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

ul.react-international-phone-country-selector-dropdown {
    z-index: 2;
    max-height: 170px;
}

.custom-snackbar {
    position: fixed;
    z-index: 1301;
    left: 0px;
    bottom: 0px;
    margin: 0 20px 20px 20px;
}

div.broadcast-date-select {
    @media (max-width: 600px) {
        flex-direction: column;
    }
}

.broadcast-invite-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    width: 100%;

    @media (max-width: 600px) {
        margin-top: 10px;
    }
}

.MobileWrapper {
    padding: 0 20px 20px 20px;

    @media (min-width: 600px) {
        display: none;
    }
}

.DesktopWrapper {
    @media (max-width: 599px) {
        display: none;
    }
}

.compaign-invite {
    &__table {
        th, td {

            @media (max-width: 599px) {
                font-size: 13px;
                padding: 16px 8px;
            }
        }

        tbody {
            word-break: break-word;
        }
    }
}

.text-draft {
    font-size: 16px;

    .ql-editor {
        font-family: "Poppins, sans-serif";
        font-size: 16px;

        ul,
        ol {
            padding: 1em;
        }
    }

    p {
        color: black
    }

    .ql-container {
        height: 350px;
    }

}

.not-found {
    margin-top: 40px;

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;

        max-width: 430px;
        margin: 0 auto;
    }

    .return-home {
        padding: 0px;
        cursor: pointer;
        background: none rgb(51, 51, 204);
        border: 1px none rgb(107, 114, 128);
        text-align: center;
        box-sizing: border-box;
        height: 40px;
        width: 153px;
        margin-top: 21px;
        font-size: 15px;
        font-weight: 600;
        color: rgb(255, 255, 255);
        line-height: 1.2;
        border-radius: 20px;
        opacity: 1;
        transition: color 200ms ease 0s, background 200ms ease 0s;
        box-shadow: none;
        text-transform: capitalize;

        &:hover {
            background: none rgba(51, 51, 204, 0.5);
        }
    }
}

.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    form {
        border: 0;
        background-color: inherit;
    }
}

// react-phone
.react-international-phone-input-container {

    .react-international-phone-country-selector button.react-international-phone-country-selector-button {
        background-color: initial;
        border-color: rgba(0, 0, 0, 0.23);
    }

    input.react-international-phone-input {
        background-color: initial;
        border-color: rgba(0, 0, 0, 0.23);
    }

    div.react-international-phone-dial-code-preview {
        background-color: initial;
        border-color: rgba(0, 0, 0, 0.23);
    }
}

.files-wrapper {
    width: 100%;
}

.pdf-wrapper {
    width: 90vw;
    height: auto !important;
    max-width: 700px;
    aspect-ratio: auto;

    @media (min-width: 600px) {
        width: 80vw;
    }
}

.react-pdf__Page {
}

// file-viewer
.file-viewer-wrapper {
    font-size: 0.7em;
    overflow-x: auto;
    
    @media (min-width: 600px) {
        font-size: initial;
    }

    .document-container {
        max-width: 80vw;

        @media (min-width: 600px) {
            max-width: 90vw;
        }

    }

    canvas {
        width: 90vw;
        height: auto !important;
        max-width: 700px;
        aspect-ratio: auto;

        @media (min-width: 600px) {
            width: 80vw;
        }
    }

    .pg-viewer-wrapper {
        overflow-y: auto;
    }

    .react-grid-Canvas {
        overflow: auto auto !important;
    }

    .sheet-names {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-bottom: 10px;
        flex-wrap: wrap;

        position: sticky;
        max-width: 90vw;
        left: 0;

        input {
            background-color: #66DEAE;
            box-shadow: none;
            color: white;
            outline: 0;
            border: 0;
            margin: 0;
            border-radius: 0;
            padding: 0;
            cursor: pointer;
            font-family: Montserrat, sans-serif;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.75;
            letter-spacing: 0.02857em;
            min-width: 64px;
            padding: 6px 16px;
            border-radius: 4px;
        }
    }
}

// emojie-picker
div.emojie-picker-wrapper aside.EmojiPickerReact div div div.epr-search-container input {
    padding: 0 30px;
}

.orange-label {
    color: var( --c-orange);
}

.cell-gap {
    padding: 0 5px;
}