 .home-content {
   margin: auto;
 }

 h1 {
   color: #3c4043;
   font-size: 2em;
   margin-bottom: 1em;
 }

 p {
   color: #5f6368;
   font-size: 1em;
   margin-bottom: 2em;
 }

 form {
   background-color: #f8f9fa;
   padding: 1em;
   border: 1px solid #dadce0;
   border-radius: 8px;
 }

 form input[type="text"],
 form textarea,
 form select {
   background: transparent;
   border: none;
   border-bottom: 1px solid #dadce0;
   border-radius: 0;
   outline: none;
   height: 3rem;
   width: 100%;
   font-size: 1rem;
   padding: 0;
   box-shadow: none;
   transition: all 0.3s;
 }


 form input[type="text"]:focus,
 form textarea:focus,
 form select:focus {
   border-bottom: 2px solid #1a73e8;
 }

 form input[type="radio"],
 form input[type="checkbox"] {
   margin-right: 0.5em;
 }

 button {
   background-color: #66DEAE;
   color: #fff;
   border: none;
   border-radius: 4px;
   cursor: pointer;
 }

 button.add-option {
   text-align: left;
 }

 button:hover {
   background-color: #2b7de9;
 }

 button.delete-option,
 button.delete-question {
   display: inline-flex;
   justify-content: center;
   align-items: center;
   color: white;
   padding: 0;
   font-size: 0.9em;
   line-height: 1;
   height: 2em;
   width: 2em;
   text-align: center;
   border-radius: 50%;
   box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
   cursor: pointer;
   transition: background-color 0.3s ease;
 }

 button.delete-option:hover,
 button.delete-question:hover {
   background-color: #ff1a1a;
 }

 button.delete-question i {
   font-size: 1em;
 }

 button.duplicate-question {
   display: flex;
   justify-content: center;
   align-items: center;

   height: 30px;
   width: 30px;
   background: none;
   color: #333;
   cursor: pointer;
   transition: all 0.3s ease;
 }

 button.duplicate-question:hover,
 button.delete-question:hover {}

 select {
   padding: 0.5em;
   font-size: 0.9em;
   border: 1px solid #dadce0;
   border-radius: 4px;
   background: #ffffff;
   appearance: none;
   position: relative;
   transition: border-color 0.3s ease-in-out;
 }

 select:focus {
   border-color: #1a73e8;
   outline: none;
 }

 select:after {
   content: "\25BC";
   position: absolute;
   top: 0;
   right: 0;
   padding: 0.5em;
   background: #dadce0;
   pointer-events: none;
   transition: 0.3s all ease;
 }

 select:hover:after {
   color: #1a73e8;
 }

 .card {
   background-color: #fff;
   padding: 1em;
   margin-bottom: 1em;
   border: 1px solid #dadce0;
   border-radius: 8px;
   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
   position: relative;
   padding: 20px;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   position: relative;
 }

 .response-type {
   display: flex;
   color: #202124;
   font-weight: 600;
   font-size: 1em;
   text-align: left;
   width: 100%;
   gap: 20px;
 }

 .card input[type="text"],
 .card textarea,
 .card select {
   width: 100%;
   padding: 0.5em;
   border-radius: 4px;
   font-size: 1em;
   box-sizing: border-box;
   color: #202124;
   font-weight: 400;
   transition: border-color 0.15s ease-in-out;
 }

 .card select {
   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5' %3E%3Cpath fill='%23333333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
   background-repeat: no-repeat;
   background-position: right 0.5em center;
   background-size: 8px 10px;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
 }

 .card input[type="text"]:focus,
 .card textarea:focus,
 .card select:focus {
   border-color: #1a73e8;
   outline: none;
 }


 .card button.delete-question {
   background-color: transparent;
   color: #5f6368;
   padding: 0;
 }

 .card button.delete-question:hover {
   color: #3c4043;
 }

 .card button.delete-option {
   background-color: transparent;
   color: #5f6368;
   padding: 0;
   margin-left: 0.5em;
   margin-top: 0;
 }

 .card button.delete-option:hover {
   color: #3c4043;
 }

 .card input[type="radio"],
 .card input[type="checkbox"] {
   margin-right: 0.5em;
 }

 .card input[type="text"],
 .card input[type="number"],
 .card textarea,
 .card select {
   width: 100%;
   padding: 0.5em;
   border-radius: 4px;
   font-size: 1em;
   box-sizing: border-box;
   color: #202124;
   font-weight: 400;
   transition: border-color 0.15s ease-in-out;
 }

 .card input[type="text"]:focus,
 .card input[type="number"]:focus,
 .card textarea:focus,
 .card select:focus {
   border-color: #1a73e8;
   outline: none;
 }

 .card input[type="number"] {
   height: 40px;
 }

 .card textarea {
   overflow: hidden;
   resize: none;
 }

 .card textarea:focus {
   overflow: auto;
 }

 .response-type>* {
   flex: 1 1 50%;
 }

 .question-container {
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
 }

 .question-inputs {
   display: flex;
   gap: 20px;
   flex-direction: column;
   align-items: flex-start;
 }

 .question-content {
   display: flex;
   justify-content: space-between;
   margin-top: 20px;
 }

 .question-controls {
   display: flex;
   gap: 20px;
   justify-content: flex-end;
 }

 .question-controls>* {
   border: 1px solid #dadce0;
   border-radius: 50%;
 }

 .button-container {
   display: flex;
   justify-content: center;
   margin-top: 1em;
 }

 .required-field {
   display: flex;
   align-items: center;
 }

 .required-field input[type="checkbox"] {
   margin: 0;
 }

 .points-input {
   width: 15%;
 }

 .trashcan-button {}

 textarea {
   // font-family: "Roboto", sans-serif;
 }

 .option-container {
   display: flex;
   align-items: center;
   justify-content: center;
   margin-bottom: 0.5em;
   width: 100%;
 }

 .option-container input[type="radio"],
 .option-container input[type="checkbox"] {
   opacity: 0;
 }

 .option-container input[type="radio"],
 .option-container input[type="checkbox"] {
   margin-right: 0.5em;
   margin-top: 40px;
 }

 .required-asterisk {
   color: red;
   margin-left: 0.5em;
 }

 .form-container {
   width: 100%;
   padding: 16px;
 }

 .form-title {
   margin-bottom: 16px;
   text-align: left;
 }

 .form-question {
   margin-bottom: 16px;
 }

 .form-question-label {
  //  font-weight: bold;
 }

 .form-input {
   width: 100%;
 }

 .form-radio-group {
   display: flex;
   flex-direction: column;
 }

 .form-checkbox-group {
   display: flex;
   flex-direction: column;
 }

 .form-range {
   width: 100%;
 }

 .linear-wrapper {
   max-width: 800px;
   margin: 0 auto;

   .MuiToggleButtonGroup-root {
     display: flex;
     justify-content: space-between;
     align-items: center;
     width: 100%;

     button.MuiToggleButtonGroup-grouped {
       width: 30px;
       height: 30px;
       border-radius: 100%;
       border: solid 2px;
       border-color: #5f6368;
       background-color: initial;

       &.Mui-selected {
         position: relative;

         // background-color: red;
         &::before {
           content: '';
           position: absolute;
           inset: 5px;
           border-radius: 100%;
           background-color: #66DEAE;
         }
       }
     }
   }


 }

 .form-range-labels {
   display: flex;
   justify-content: space-between;
   margin: 0 auto 20px auto;
   width: 100%;
 }

 .form-answers-list {
   background-color: initial;
   padding: 0;
   border: 0;
   border-radius: 0;
   margin-bottom: 30px;

   .form-title {
     background-color: white;
     border-radius: 10px;
     padding: 15px 20px;
     border: 1px solid #e0e0e0;

     .form-description {
       color: black;
       white-space: pre-wrap;
     }

     .form-required {
       color: rgb(252, 64, 64);
       margin-bottom: 10px;
       margin-top: 15px;
     }
   }

   .form-answers-wrapper {
     background-color: white;
     border-radius: 10px;
     border: 1px solid #e0e0e0;
     padding: 15px 20px;

     &>div>div:first-of-type {
       margin-top: 20px;
     }
   }
 }

 .forms-wrapper {
   text-align: left;
 }

 .MuiDialog-container .dialog-actions-form {
   overflow-x: auto;
   justify-content: flex-start;


   button {
     flex-shrink: 0;
   }

   @media (min-width: 800px) {
     justify-content: flex-end;
   }
 }