.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 72px; 
  text-align: center;
  background-color: var(--bg-main);
  animation: enter 1s ease;
}

@keyframes enter {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
   opacity: 1;
  }
}

.page-container {
  width: 100%;
  padding: 0 10px;

  @media (min-width: 600px) {
    padding: 0 30px;
}
}

.content {
  display: flex;
  margin-left: 300px;
  flex-grow: 1;
  overflow: auto; 
  margin-top: 20px;
  @media (max-width: 1200px) {
    justify-content: center;
    margin-left: 0px;
  }
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.drop-area {
  padding: 50px 100px;
  border: 3px dotted #666;
  border-radius: 10px;

  text-align: center;
  cursor: pointer;
}

a,a:visited {
  text-decoration: none;
  color: initial;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

div div.custom-height {
  input.MuiInputBase-input {
    height: auto;
  }
}

// Loader
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #66DEAE;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #66DEAE transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.container-shrinked {
  max-width: 1000px;
  margin: 0 auto;
}

.full-width {
  width: 100%;
}

.text-link {
  color: rgb(51, 51, 204);
  text-decoration: underline;
  cursor: pointer;
}
