.admin-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;

    @media (max-width: 410px) {
        gap: 10px
    }
}