.home-content {
    margin-left: 300px;
    padding: 20px;
}

.home-container {
  justify-content: space-between;
}

.video-wrapper {
  position: relative;
  padding-top: 5%;
}

.profile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-avatar{
  margin-bottom: 20px;
}

.profile-elements {
  display: flex;
  flex-direction: column;
}

.profile-element {
  margin: 10px !important
}

@media (min-width: 1199px) {
  .profile-content {
    margin-left: -300px;
  }

  div.margin-left-empty {
    margin-left: 0;
  }
}

@media (min-width: 900px) {
  .video-wrapper {
    padding-top: 25%;
  }
}

@media (min-width: 1600px) {
  .home-container {
    justify-content: space-evenly;
  }
}

