/* Main container for the side menu */
.side-menu {
  position: fixed;
  top: -18px;
  left: 0;
  height: 100%;
  width: 300px;
  background-color: var(--bg-main);
  z-index: 999;
  transition: left 0.3s ease-in-out;
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: 'Montserrat', sans-serif;
}

@keyframes slideIn {
  from {
    left: -300px;
  }

  to {
    left: 0;
  }
}

@keyframes slideOut {
  from {
    left: 0;
    /* Starting position */
  }

  to {
    left: -300px;
    /* Final position */
  }
}

.menu-icon {
  display: none;
}

.menu-icon.show-menu {
  display: none;
  flex-shrink: 0;
  font-size: 30px;
  color: #66DEAE;
  z-index: 1000;
  cursor: pointer;
  position: relative;
  top: -4px;
  margin-right: 5px;
}

.side-menu.open {
  animation: slideIn 0.3s ease-in-out forwards;
}

.side-menu.closed {
  animation: slideOut 0.3s ease-in-out forwards;
}

@media (max-width: 1200px) {
  .menu-icon.show-menu {
    display: block !important;
  }

  .menu-icon {
    display: none;
  }

  .side-menu {
    display: none;
  }

  .side-menu.open {
    display: block;
  }

  .overlay {
    display: none;
  }

  .side-menu.open+.overlay {
    display: block;
  }
}

/* Show the menu when the "open" class is added */
.side-menu.open {
  left: 0;
}

/* Menu button styles */
.side-menu button {
  position: absolute;
  top: 10px;
  left: 10px;
  margin: 10px;
  padding: 10px 20px;
  font-size: 20px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 4px;
}

/* Navigation links styles */
.side-menu ul {
  margin: 0;
  padding: 15px 0 20px 0;
  list-style: none;
}

.side-menu li {
  margin: 0px;
  padding: 0px;
}

.side-menu a {
  display: flex;
  align-items: center;
  text-align: left;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 22px;
  font-weight: 600;
  color: rgb(51, 51, 204);
  text-decoration: none;
  transition: background-color 0.3s ease-in-out;
}

.side-menu a:hover {
  background-color: #BFFFE6;
}

.side-menu-selected {
  background-color: #BFFFE6;
  border-radius: 20px;
  display: flex !important;
  justify-content: space-between;
}

.side-menu-bubble {
  background-color: #00ff9d;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  flex-shrink: 0;
}

/* Overlay styles */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 998;
  /* Add a lower z-index value than the .side-menu */

}

/* Show the overlay when the menu is open */
.side-menu.open+.overlay {
  display: block;
}